import { AssetTemplate, QueryKeyFunction, SearchFilter } from "../../types";

export const sitesListQueryKey: QueryKeyFunction = () => ["accelerator", "sites", "list"];
export const siteSummaryQueryKey: QueryKeyFunction = () => ["accelerator", "sites", "summary"];

export const assetsListQueryKey: QueryKeyFunction = () => ["accelerator", "assets", "list"];
export const assetByIdQueryKey: QueryKeyFunction = (assetId: string) => [
  "accelerator",
  "asset",
  "single",
  assetId
];

export const assetSubTypesListQueryKey: QueryKeyFunction = (assetTypeId: string) => [
  "accelerator",
  "assetSubTypes",
  "list",
  { assetTypeId }
];

export const assetTypesListQueryKey: QueryKeyFunction = () => ["accelerator", "assetTypes", "list"];

export const assetTypeByIdQueryKey: QueryKeyFunction = (assetTypeId: string) => [
  "accelerator",
  "assetTypes",
  "single",
  assetTypeId
];
export const assetSubTypeByIdQueryKey: QueryKeyFunction = (assetSubTypeId: string) => [
  "accelerator",
  "assetSubTypes",
  "single",
  assetSubTypeId
];

export const alarmModesListQueryKey: QueryKeyFunction = () => ["accelerator", "alarmModes", "list"];

export const alarmModeByIdQueryKey: QueryKeyFunction = (alarmModeId: string) => [
  "accelerator",
  "alarmModes",
  "single",
  alarmModeId
];

export const assetPropertiesListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetProperties",
  "list"
];

export const assetPropertyByIdQueryKey: QueryKeyFunction = (identifierId: string) => [
  "accelerator",
  "assetProperties",
  "single",
  identifierId
];

export const assetPersonaTemplateByIdQueryKey: QueryKeyFunction = (templateId: string) => [
  "accelerator",
  "assetPersonaTemplates",
  "single",
  templateId
];

export const templatesListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetProperties",
  "list"
];
export const templatesSearchListQueryKey: QueryKeyFunction = (
  filters: SearchFilter<AssetTemplate>
) => ["accelerator", "assetProperties", "search", filters];

export const templateByIdQueryKey: QueryKeyFunction = (identifierId: string) => [
  "accelerator",
  "assetProperties",
  "single",
  identifierId
];

export const searchTemplateByIdQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "assetProperties",
  "single",
  "search",
  id
];

export const searchPersonasTemplateByIdQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "assetProperties",
  "single",
  "personasSearch",
  id
];

export const personasListQueryKey: QueryKeyFunction = () => ["accelerator", "personas", "list"];

export const assetNameplateDataListQueryKey: QueryKeyFunction = (assetId: string) => [
  "accelerator",
  "assetNameplateData",
  assetId,
  "list"
];
export const nameplateLabelsListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetNameplateLabels",
  "list"
];

export const nameplateLabelQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "assetNameplateLabels",
  "single",
  id
];
export const plantsListQueryKey: QueryKeyFunction = () => ["accelerator", "plants", "list"];

export const plantQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "plants",
  "single",
  id
];

export const tagNotificationsListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "tagNotifications",
  "list"
];

export const productionUnitsQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "productionUnit",
  "single",
  id
];

export const productionUnitsListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "productionUnits",
  "list"
];

export const subAreasListQueryKey: QueryKeyFunction = () => ["accelerator", "subAreas", "list"];

export const subAreasByIdQueryKey: QueryKeyFunction = (id: string) => [
  "accelerator",
  "subAreas",
  "single",
  id
];

export const steadyStateListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "steadyState",
  "list"
];

export const steadyStateGetByIdQueryKey = (id: string) =>
  ["accelerator", "steadyState", "getById", id] as const;

export const tagMappingByIdQueryKey: QueryKeyFunction = (
  assetId: string,
  assetPropertyId: string
) => ["accelerator", "tagMapping", "single", assetId, assetPropertyId];

export const searchTungstenTagsByIdQueryKey: QueryKeyFunction = (
  tagName: string,
  system: string,
  query: string
) => ["accelerator", "tagMapping", tagName, system, query];

export const vEmptyTemplatesListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "vemptyTemplates",
  "list"
];

export const dataSourcesListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "dataSources",
  "list"
];

export const dataSourcesByIdQueryKey: QueryKeyFunction = (dataSourceId: string) => [
  "accelerator",
  "dataSources",
  "single",
  dataSourceId
];

export const tagWeightsByIdQueryKey: QueryKeyFunction = (tagWeightsId: string) => [
  "accelerator",
  "tagWeights",
  "single",
  tagWeightsId
];

export const assetPropertyNoteByIdQueryKey: QueryKeyFunction = (assetPropertyNoteId: string) => [
  "accelerator",
  "assetPropertyNote",
  "single",
  assetPropertyNoteId
];

export const assetNoteByIdQueryKey: QueryKeyFunction = (assetNoteId: string) => [
  "accelerator",
  "assetNote",
  "single",
  assetNoteId
];

export const assetNoteSearchByIdQueryKey: QueryKeyFunction = (assetNoteId: string) => [
  "accelerator",
  "assetNote",
  "single-search",
  assetNoteId
];

export const assetNoteAttachmentQueryKey: QueryKeyFunction = (assetNoteId: string) => [
  "accelerator",
  "assetNoteAttachment",
  "single",
  assetNoteId
];

export const assetNoteAttachmentByIdQueryKey: QueryKeyFunction = (
  assetNoteId: string,
  assetNoteAttachmentId: string
) => ["accelerator", "assetNoteAttachment", "single", assetNoteId, assetNoteAttachmentId];

export const assetAnnotationSourceListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetAnnotationSource",
  "list"
];

export const assetAnnotationActionListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetAnnotationAction",
  "list"
];

export const assetAnnotationImpactListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetAnnotationImpact",
  "list"
];

export const assetAnnotationReliabilityCategoryListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetAnnotationReliabilityCategory",
  "list"
];

export const assetAnnotationPlantStatusListQueryKey: QueryKeyFunction = () => [
  "accelerator",
  "assetAnnotationPlantStatus",
  "list"
];

export const tagsByIdQueryKey: QueryKeyFunction = (tagId: string) => [
  "accelerator",
  "tags",
  "single",
  tagId
];

export const csvSampleFileByIdQueryKey: QueryKeyFunction = (pageConfigKey: string) => [
  "accelerator",
  "csvDownload",
  "single",
  pageConfigKey
];
